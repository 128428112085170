import * as React from "react"
import { Link } from "gatsby"
import CartIcon from "../icons/cart"
import {
  cartButton,
  badge,
  cartIcon,
  black,
  white,
} from "./cart-button.module.css"
import classNames from "classnames"

export function CartButton({ quantity, stroke }) {
  const classes = classNames(cartIcon, {
    [black]: stroke === "#000",
    [white]: stroke === "#fff",
  })
  return (
    <Link
      aria-label={`Shopping Cart with ${quantity} items`}
      to="/cart"
      className={cartButton}
    >
      <CartIcon className={classes} stroke={stroke} />
      {quantity > 0 && <div className={badge}>{quantity}</div>}
    </Link>
  )
}
