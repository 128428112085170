import * as React from "react"
import { useState, useEffect } from "react"

function HeartLogo(props) {
  const { classes, color: colorProp } = props
  const [color, setColor] = useState(colorProp)

  useEffect(() => {
    setColor(colorProp)
  }, [colorProp])

  return (
    <i className={classes}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 211 125">
        <g fill={color} clipPath="url(#a)">
          <path d="M71.907 20.589c11.199-27.122 55.086-27.766 66.609-.053 2.867-3.68 5.424-8.032 8.966-11.33 13.368-12.462 35.152-11.98 48.222.845 12.346 12.105 16.737 27.005 14.887 43.96-2.3 21.072-13.735 36.512-30.236 48.765-12.111 8.996-25.479 15.791-39.224 21.852-1.31.577-3.401.461-4.695-.194-9.726-4.897-19.373-9.951-28.968-15.11-1.515-.813-2.427-.818-3.952 0-9.59 5.159-19.242 10.213-28.968 15.105-1.3.655-3.4.781-4.7.199-15.391-6.895-30.41-14.465-43.537-25.266C13.001 88.41 3.721 74.957.911 57.624c-2.871-17.757.85-33.633 13.63-46.866C29.818-5.059 59.3-3.707 70.844 18.675c.273.535.582 1.049 1.064 1.908v.006ZM8.211 48.753c.624 15.897 7.127 28.998 18.478 39.835 12.76 12.18 28.234 20.023 43.992 27.394.686.32 1.771.357 2.442.048 14.421-6.701 28.57-13.894 40.738-24.359 11.424-9.83 19.169-21.763 21.286-37.014 1.902-13.716-1.001-26.02-10.193-36.57-7.614-8.734-18.865-11.869-28.58-7.7-11.24 4.817-17.11 14.134-20.201 25.5-.718 2.648-2.044 4.084-4.334 4.006-2.311-.079-3.464-1.584-4.13-4.247-.985-3.953-1.902-8.021-3.594-11.691C56.7 7.906 36.598 3.25 22.905 14.486 12.157 23.299 8.038 35.179 8.211 48.758v-.005Zm104.366 54.584c8.929 4.482 17.23 8.682 25.573 12.792.576.283 1.572.126 2.211-.173 14.269-6.616 28.24-13.762 40.303-24.065 9.411-8.037 16.727-17.558 19.986-29.727 3.883-14.501 3.024-28.4-6.084-40.957-6.917-9.536-16.208-14.155-28.093-12.368-11.371 1.715-17.937 9.06-21.412 19.472-1.053 3.162-1.902 6.69-1.614 9.94.745 8.447.566 16.752-1.682 24.925-1.142 4.158-2.714 8.2-4.176 12.546h2.609c8.443.058 16.088 2.774 23.236 7.063 2.18 1.305 2.777 3.58 1.666 5.64-1.079 2.004-3.322 2.638-5.659 1.574-.634-.289-1.237-.645-1.84-.996-7.425-4.362-15.458-5.726-23.932-4.813-.796.084-1.85.34-2.269.901-5.12 6.832-11.335 12.541-18.828 18.246h.005Z" />
          <path d="M71.498 90.507c-8.248.278-16.37-2.26-23.958-6.768-2.542-1.51-3.38-3.68-2.264-5.862 1.106-2.16 3.616-2.904 6.094-1.447 13.73 8.09 27.486 7.64 41.336.378 1.886-.991 3.752-.928 5.266.681 1.331 1.41 1.546 3.125.535 4.819-.383.64-1.001 1.247-1.656 1.604-7.703 4.157-15.805 6.873-25.353 6.6v-.005ZM49.673 45.54c.036 4.42-3.48 7.958-7.934 7.98-4.271.02-7.83-3.445-7.944-7.734-.116-4.32 3.49-8.074 7.834-8.153 4.36-.084 8.007 3.502 8.044 7.906ZM112.378 45.471c.058 4.462-3.385 8-7.84 8.053a7.84 7.84 0 0 1-7.95-7.67c-.11-4.561 3.465-8.258 7.961-8.226 4.271.031 7.771 3.534 7.829 7.843ZM178.929 45.644a7.87 7.87 0 0 1-8.022 7.88c-4.46-.063-7.829-3.58-7.761-8.105.068-4.31 3.558-7.775 7.86-7.791 4.381-.016 7.944 3.591 7.923 8.016Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h211v125H0z" />
          </clipPath>
        </defs>
      </svg>
    </i>
  )
}

export default HeartLogo
