import * as React from "react"
import { useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { StoreContext } from "../context/store-context"
import { CartButton } from "./cart-button"
import HeartLogo from "../icons/heartLogo"
import { Toast } from "./toast"
import {
  header,
  container,
  home,
  logo,
  logoContainer,
  hiddenContainer,
  homeLogo,
} from "./header.module.css"
import classNames from "classnames"

const isBrowser = typeof window !== "undefined"

export function Header(props) {
  const { path } = props
  const { checkout, loading, didJustAddToCart } = React.useContext(StoreContext)
  const items = checkout ? checkout.lineItems : []

  useEffect(() => {
    console.log("dssd")
  }, [])

  const quantity = items.reduce((total, item) => {
    return total + item.quantity
  }, 0)

  return (
    <div className={container}>
      <header className={header}>
        <div className={hiddenContainer}></div>
        <div className={logoContainer}>
          <Link to="/">{renderLogo()}</Link>
        </div>
        {/* <Navigation className={nav} /> */}
        {/* <Link to="/search" className={searchButton}>
          <SearchIcon />
        </Link> */}
        {renderCart()}
      </header>
      <Toast show={loading || didJustAddToCart}>
        {!didJustAddToCart ? (
          "Updating…"
        ) : (
          <>
            Added to cart{" "}
            <svg
              width="14"
              height="14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.019 10.492l-2.322-3.17A.796.796 0 013.91 6.304L6.628 9.14a1.056 1.056 0 11-1.61 1.351z"
                fill="#fff"
              />
              <path
                d="M5.209 10.693a1.11 1.11 0 01-.105-1.6l5.394-5.88a.757.757 0 011.159.973l-4.855 6.332a1.11 1.11 0 01-1.593.175z"
                fill="#fff"
              />
              <path
                d="M5.331 7.806c.272.326.471.543.815.163.345-.38-.108.96-.108.96l-1.123-.363.416-.76z"
                fill="#fff"
              />
            </svg>
          </>
        )}
      </Toast>
    </div>
  )

  function renderLogo() {
    if (!isBrowser) {
      return <HeartLogo classes={classNames(logo)} color={"#fff"} />
    }

    if (isBrowser && path === "/") {
      return <HeartLogo classes={classNames(logo)} color={"#fff"} />
    }

    if (isBrowser && path !== "/") {
      return <HeartLogo classes={classNames(logo)} color={"#000"} />
    }
  }

  function renderCart() {
    if (!isBrowser) {
      return <CartButton quantity={quantity} stroke={"#fff"} />
    }

    if (isBrowser && path === "/") {
      return <CartButton quantity={quantity} stroke={"#fff"} />
    }

    if (isBrowser && path !== "/") {
      return <CartButton quantity={quantity} stroke={"#000"} />
    }
  }
}
